export default {
  portfolio:
    "Пакет документов объемом не более 20 листов формата А4, включающий в себя рекомендательные письма, копии наградных документов (грамоты, дипломы) фотографии, иллюстрации, сопровождающиеся комментариями и в полной мере отражающие активную трудовую и (или) общественную деятельность участника Конкурса в сфере молодежной политики за предыдущие 5 лет.",
  anketa:
    "Приложение 1 ПОЛОЖЕНИЯ о Конкурсе среди лидеров молодежной политики Республики Татарстан на получение жилья по программе социальной ипотеки в Республике Татарстан",
  skan1:
    "Финансово-лицевой счет должен быть с места прописки. Если члены семьи из учетного дела, прописаны в разных местах, то Финансово-лицевой счет должны быть с каждого места прописки.",
  skan2:
    " Справка заказывается в МФЦ по месту жительства на всех членов семьи, в том числе и на детей. Сведения в справке должны содержать данные только по Республике Татарстан за весь период жизни. Справку необходимо заказывать только на заявителя и членов семьи, которые будут вставать на учет вместе с заявителем.",
  skan3:
    " Справка заказывается в МФЦ по месту жительства на всех членов семьи, в том числе и на детей. Сведения в справке должны содержать данные только по Республике Татарстан за весь период жизни. Справку необходимо заказывать только на заявителя и членов семьи, которые будут вставать на учет вместе с заявителем.",
};
